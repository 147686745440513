<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="seller.password"
        @click:append="showPassword = !showPassword"
        label="Senha"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        rounded
        outlined
        dense
        :rules="passwordRules"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        @click:append="showPassword = !showPassword"
        label="Repetir senha"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[(value) => value === seller.password || 'Senha não confere']"
        rounded
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    seller: Object,
  },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    passwordRules() {
      // Se this.$route.params.id existir, não validar a senha
      return this.$route.params.id
        ? []
        : [
            (value) =>
              (value && value.length >= 8) ||
              'A senha deve ter pelo menos 8 caracteres',
          ]
    },
  },
}
</script>
